import React, { Component } from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import "../components/layout.css";

import NavBar from "../components/NavBar";
import ShowcaseHero from "../components/ShowcaseHero";
import BlockRight from "../components/ShowcaseBlockRight";
import BlockLeft from "../components/ShowcaseBlockLeft";
import Footer from "../components/Footer";
import SEO from "../components/SEO";
import Contact from "../components/Contact";

class Showcase extends Component {

  render() {

    const {
      data: { seo, hero, block, contact },
    } = this.props

    const seoData = seo.primary;

    const contactData = contact.primary.cta.document.data;

    return(
      <React.Fragment>
        <SEO 
          title={ seoData.seo_title }
          description={ seoData.seo_description }
          />
        <div className="page-container">
          <NavBar />
          <ShowcaseHero
            title={ hero.data.showcase_hero_title.text }
            teaser={ hero.data.showcase_hero_teaser.text }
            image={ hero.data.showcase_hero_image.url }
            alt={ hero.data.showcase_hero_image.alt }
            />
          <div className="showcase-block-wrapper">
            {block.items.map((m, i) => {
              const blockData = m.model.document.data;
              if (i%2 === 0) {
                return (
                  <BlockLeft
                    title={ blockData.model_title.text }
                    teaser={ blockData.model_teaser.text }
                    image={ blockData.model_image.url }
                    alt={ blockData.model_image.alt }
                  />
                )
              } else {
                return (
                  <BlockRight
                    title={ blockData.model_title.text }
                    teaser={ blockData.model_teaser.text }
                    image={ blockData.model_image.url }
                    alt={ blockData.model_image.alt }
                  />
                )
              }
            })}
          </div>
          <Contact
            title={ contactData.contact_field_title.text }
            teaser={ contactData.contact_field_teaser.text }
            portalId={ contactData.hubspot_portal_id.text }
            formId={ contactData.hubspot_form_id.text }
            />
        </div>
        <Footer />
      </React.Fragment>
    )
  }
}

export default Showcase

Showcase.propTypes = {
  data: PropTypes.shape({
    seo: PropTypes.shape({
      primary: PropTypes.shape({
        seo_title: PropTypes.string.isRequired,
        seo_description: PropTypes.string.isRequired,
      }),
    }),
    hero: PropTypes.shape({
      data: PropTypes.shape({
        showcase_hero_title: PropTypes.shape({
          text: PropTypes.string.isRequired,
        }),
        showcase_hero_teaser: PropTypes.shape({
          text: PropTypes.string.isRequired,
        }),
        showcase_hero_image: PropTypes.shape({
          url: PropTypes.string.isRequired,
          alt: PropTypes.string.isRequired,
        }),
      }),
    }),
    block: PropTypes.shape({
      items: PropTypes.array.isRequired
    }),
    contact: PropTypes.shape({
      primary: PropTypes.shape({
        cta: PropTypes.shape({
          document: PropTypes.array.isRequired
        }),
      }),
    }),
  }),
}

export const showcaseQuery = graphql`
  query showcasePage {

    seo: prismicShowcasePageBody1SeoTags {
      primary {
        seo_title
        seo_description
      }
    }

    hero: prismicShowcasePage {
      data {
        showcase_hero_title {
          text
        }
        showcase_hero_teaser {
          text
        }
        showcase_hero_image {
          url
          alt
        }
      }
    }

    block: prismicShowcasePageBodyShowcaseModel {
      items {
        model {
          document {
            ... on PrismicShowcaseModel {
              data {
                model_title {
                  text
                }
                model_teaser {
                  text
                }
                model_image {
                  url
                  alt
                }
              }
            }
          }
        }
      }
    }

    contact: prismicShowcasePageBodyCta {
      primary {
        cta {
          document {
            ... on PrismicCta {
              data {
                contact_field_title {
                  text
                }
                contact_field_teaser {
                  text
                }
                hubspot_portal_id {
                  text
                }
                hubspot_form_id {
                  text
                }
              }
            }
          }
        }
      }
    }
    
  }
`