import React from "react";
import "./style.css";

export default ({ title, teaser, image, alt }) => (
  <React.Fragment>
    <div className="showcaseR-block-box">
      <div className="showcaseR-blockImage-wrapper">
        <img src={ image } alt={ alt } className="showcaseR-block-image" />
      </div>
      <div className="showcaseR-block-info">
        <h1 className="showcaseR-block-title  text-med">
          { title }
        </h1>
        <p className="showcaseR-block-teaser  text-reg">
          { teaser }
        </p>
      </div>
    </div>
  </React.Fragment>
)