import React from "react";
import "./style.css";

export default ({ title, teaser, image, alt }) => (
  <React.Fragment>
    <div className="showcase-hero-wrapper">
      <div className="showcase-hero-info">
        <h1 className="showcase-hero-title  text-med">
          { title }
        </h1>
        <p className="showcase-hero-teaser  text-reg">
          { teaser }
        </p>
      </div>
      <div className="showcase-image-wrapper">
        <img src={ image } alt={ alt } className="showcase-hero-image" />
      </div>
    </div>
  </React.Fragment>
)